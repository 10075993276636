import styled from "styled-components"
import { ChromePicker } from "react-color"
import { Typography } from "@material-ui/core"

export const Wrapper = styled.div`
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-bottom: 2em;

  .circle-picker {
    > span {
      > div {
        border: 1px solid lightgray;
        border-radius: 50%;
      }
    }
  }
`

export const CustomColorPicker = styled(ChromePicker)`
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  overflow: hidden;
  font-family:  ${({ theme }) => theme.fonts.primaryFont} !important;
  > div {
    border-radius: 8px !important;
  }
`

export const ChooseTitle = styled(Typography)`
font-size:  ${({ theme }) => theme.typography.pxToRem(12)};
  color: #404040;
  opacity: .4;
  margin-bottom: 1.5em;
`