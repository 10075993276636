import React from "react"
import * as S from "./configurator-item.styles.jsx"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

const ConfiguratorItem = ({ handleSelection, item }) => {
  const { name, type } = item
  return (
    <S.Wrapper onClick={() => handleSelection(item)}>
      <S.ItemName>{name}</S.ItemName>
      <div>
        <span>CHOOSE COLOR</span>
        <ArrowForwardIosIcon />
      </div>
    </S.Wrapper>
  )
}
export default ConfiguratorItem