import React, { useContext, useEffect, useState } from "react"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import {
  ChooseTitle,
  Wrapper,
} from "../cofigurator-color-option/configurator-color-option.styles"
import { ConfiguratorContext } from "../../provider"
import queryString from "query-string"


const ConfiguratorRadioOption = ({ api, item }) => {
  const { radioOptions, name } = item
  const [node, setNode] = useState(radioOptions[0].radioOptionId)
  const contextSetMaterial = useContext(ConfiguratorContext).setMaterial

  useEffect(() => {
    contextSetMaterial({ [item.id]: node })
    radioOptions.map(({ radioOptionId }) => {
      if (radioOptionId === node) {
        api.show(radioOptionId, () => {
        })
      } else {
        api.hide(radioOptionId, () => {
        })
      }
    })

  }, [node])

  const handleChange = event => {
    setNode(parseInt(event.target.value))
  }

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      const parameters = window.location.search ? queryString.parse(window.location.search) : ""
      if (parameters !== "") {
        if (item.id in parameters) {
          const defaultOpt = parameters[item.id]
          setNode(parseInt(defaultOpt))
        }
      }
    }
  }, [])

  return (
    <Wrapper>
      <ChooseTitle>{name}</ChooseTitle>
      <RadioGroup aria-label="gender" name="option" value={node} onChange={handleChange}>
        {radioOptions.map(({ radioOptionId, radioOptionName }) => (
          <FormControlLabel
            key={radioOptionId}
            value={radioOptionId}
            control={<Radio color='primary'/>}
            label={radioOptionName}
          />
        ))}
      </RadioGroup>
    </Wrapper>
  )
}
export default ConfiguratorRadioOption