import React, { useContext } from "react"
import * as S from "./saved-color-picker.styles.jsx"
import { Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { CirclePicker } from "react-color"
import { ConfiguratorContext } from "../../provider"

const SavedColorPicker = ({ handleSetColor, currentColor }) => {
  const handleOnChange = color => handleSetColor(color)
  const { addColor, savedColors } = useContext(ConfiguratorContext)

  return (
    <S.Wrapper>
      <S.TopWrapper>
        <Typography>SAVED COLORS</Typography>
        <S.CustomLink onClick={() => addColor(currentColor)}>
          <span>SAVE COLOR</span>
          <AddIcon />
        </S.CustomLink>
      </S.TopWrapper>
      <CirclePicker
        color={currentColor}
        circleSize={32}
        circleSpacing={18}
        onChange={handleOnChange}
        colors={savedColors}
      />
    </S.Wrapper>
  )
}
export default SavedColorPicker
