import React, { useContext, useEffect, useState } from "react"
import * as S from "./configurator-color-option.styles"
import SavedColorPicker from "../saved-color-picker/saved-color-picker.component"
import { ConfiguratorContext } from "../../provider"
import { CirclePicker } from "react-color"

const ConfiguratorColorOption = ({ api, item }) => {
  const { colors, selectionType } = item
  const rgbHex = require("rgb-hex")
  const [material, setMaterial] = useState(null)
  const contextSetMaterial = useContext(ConfiguratorContext).setMaterial
  const contextMaterials = useContext(ConfiguratorContext).materials
  const [color, setColor] = useState("#FFFFFF")
  const handleOnChange = color => {
    if (material) {
      setColor(color.hex)
      setMaterial({
        ...material,
        channels: {
          ...material.channels,
          AlbedoPBR: {
            color: [color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255],
          },
        },
      })
    }
  }

  useEffect(() => {
    if (material) {
      contextSetMaterial({ [item.id]: color.replace("#", "") })
      api.setMaterial(material, () => {})
    }
  }, [material])

  useEffect(() => {
    api.getMaterialList((err, materials) => {
      const currentMaterial = materials.find(
        material => material.name === item.id
      )

      if (currentMaterial) {
        const currentColor = currentMaterial.channels.AlbedoPBR.color
        const contextColor =
          contextMaterials && contextMaterials[item.id]
            ? contextMaterials[item.id]
            : null
        if (contextColor) {
          setColor("#" + contextColor)
        } else {
          setColor(
            "#" +
              rgbHex(
                currentColor[0] * 255,
                currentColor[1] * 255,
                currentColor[2] * 255
              )
          )
        }
        setMaterial(currentMaterial)
      }
    })
  }, [item])

  return (
    <S.Wrapper>
      <S.ChooseTitle>{item.name}</S.ChooseTitle>
      {selectionType === "colorWheel" && (
        <S.CustomColorPicker
          color={color}
          disableAlpha
          onChange={handleOnChange}
        />
      )}
      {selectionType === "selectColor" && (
        <CirclePicker
          color={color}
          circleSize={32}
          circleSpacing={18}
          onChange={handleOnChange}
          colors={colors && colors.map(color => `#${color.hexColor}`)}
        />
      )}

      {selectionType !== "selectColor" && (
        <SavedColorPicker
          currentColor={color}
          handleSetColor={handleOnChange}
        />
      )}
    </S.Wrapper>
  )
}
export default ConfiguratorColorOption
