import React, { useContext, useEffect, useState } from "react"
import Layout from "../../components/layout"
import SwipeableViews from "react-swipeable-views"

import * as S from "../../styles/templates/boat-builder.styles"
import HeaderConfig from "../../components/header-config/header-config.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import RotateLeftIcon from "@material-ui/icons/RotateLeft"
import ShareIcon from "@material-ui/icons/Share"
import Drawer from "@material-ui/core/Drawer"
import CloseIcon from "@material-ui/icons/Close"
import { Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Tab from "@material-ui/core/Tab"
import TabPanel from "../../components/tab-panel/tab-panel.component"
import ConfiguratorItem from "../../components/configurator-item/configurator-item.component"

import ConfiguratorColorOption from "../../components/cofigurator-color-option/configurator-color-option.component"
import Slide from "@material-ui/core/Slide"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LoadableViewer from "../../components/viewer/loadable-viewer"
import ConfiguratorRadioOption from "../../components/configurator-radio-option/configurator-radio-option.component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ConfiguratorContext } from "../../provider"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { setUrlMaterials } from "../../utils/utils"
import { graphql } from "gatsby"
import { use100vh } from "react-div-100vh"

export const query = graphql`
  query BoatBuilderQuery($id: String!) {
    wpBoat(id: { eq: $id }) {
      title
      content
      seo {
        cornerstone
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
      }
      id
      uri
      slug
      boatPostTypeFields {
        generalInfo {
          name
        }
        configurator {
          modelUid
          modifiers {
            name
            type
            options {
              id
              name
              type
              selectionType
              colors {
                hexColor
              }
              radioOptions {
                radioOptionId
                radioOptionName
              }
            }
          }
        }
      }
    }
  }
`

const BoatBuilder = ({ data }) => {
  const {
    title,
    slug,
    seo,
    boatPostTypeFields: { configurator },
  } = data.wpBoat

  const { modelUid, modifiers } = configurator
  const { materials, shareableQueryString } = useContext(ConfiguratorContext)
  const matches = useMediaQuery("(min-width:650px)")

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [api, setApi] = useState(null)
  const [isViewerReady, setIsViewerReady] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const interiorItems = modifiers
    ? modifiers.filter(item => item.type === "INTERIOR")
    : []
  const exteriorItems = modifiers
    ? modifiers.filter(item => item.type === "EXTERIOR")
    : []

  useEffect(() => {
    if (api) {
      api.addEventListener("viewerready", () => {
        setUrlMaterials(api, modifiers)
        setIsViewerReady(true)
      })
    }
  }, [api])

  const handleChangeIndex = index => {
    setTabIndex(index)
  }

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue)
  }

  const handleSelectConfigItem = item => {
    setSelectedOption(item)
  }

  const handleBack = () => {
    setSelectedOption(null)
  }

  const handleNext = () => {
    const items =
      selectedOption.type === "INTERIOR" ? interiorItems : exteriorItems

    let currentIndex = items.findIndex(opt => selectedOption.name === opt.name)

    if (currentIndex === items.length - 1) {
      currentIndex = -1
    }

    setSelectedOption(items[currentIndex + 1])
  }

  const handleReset = () => {
    if (typeof window !== "undefined" && window) {
      window.location.href = `/boat-builder/${slug}`
    }
  }

  const handleOption = (option, index) => {
    switch (option.type) {
      case "materialColor":
        return (
          <ConfiguratorColorOption
            key={`selected-color-${index}`}
            api={api}
            item={option}
          />
        )

      case "radioSelect":
        return (
          <ConfiguratorRadioOption
            key={`selected-radio-${index}`}
            api={api}
            item={option}
          />
        )
    }
  }

  const selectedOptTitle = (
    <S.TitleWrapper>
      <IconButton onClick={handleBack}>
        <ArrowBackIosIcon />
      </IconButton>
      <Typography>{selectedOption ? selectedOption.name : ""}</Typography>
      <IconButton onClick={handleNext}>
        <ArrowForwardIosIcon />
      </IconButton>
    </S.TitleWrapper>
  )

  const height = use100vh()
  // console.log("height", height)

  return (
    <Layout seo={seo} hideHeader hideFooter>
      {isViewerReady && (
        <HeaderConfig
          boatName={title}
          handleOpenDrawer={() => setIsOpenDrawer(true)}
        />
      )}
      <S.Wrapper height={height}>
        <S.InnerWrapper isOpenDrawer={isOpenDrawer}>
          <LoadableViewer autoplay={true} setApi={setApi} modelUid={modelUid} />
          {isViewerReady && matches && (
            <S.BottomActions>
              <div>
                <div>
                  <CustomButton href={`/${slug}`} className="light">
                    Learn More
                  </CustomButton>
                </div>
                <div>
                  <S.ActionButton onClick={handleReset}>
                    <RotateLeftIcon />
                    <span>Reset</span>
                  </S.ActionButton>
                  <CopyToClipboard
                    text={`${window.location.origin}${window.location.pathname}${shareableQueryString}`}
                    onCopy={() => alert("URL Copied!")}
                  >
                    <S.ActionButton>
                      <ShareIcon />
                      <span>Copy Shareable Link</span>
                    </S.ActionButton>
                  </CopyToClipboard>
                  <CustomButton href="/contact-us">Contact Us</CustomButton>
                </div>
              </div>
            </S.BottomActions>
          )}
          {!matches && (
            <S.CopyrightWrapper className="mobile">
              <Typography>
                Built by{" "}
                <a
                  href="https://aftershock.agency/?utm_source=midnightboats+powered+by+link&utm_medium=link+on+bottom+of+website&utm_campaign=midnightboats+powered+by+link"
                  target="_blank"
                >
                  Aftershock
                </a>
              </Typography>
            </S.CopyrightWrapper>
          )}
        </S.InnerWrapper>
      </S.Wrapper>
      <Drawer variant="persistent" anchor="right" open={isOpenDrawer}>
        <S.DrawerWrapper height={height}>
          <S.DrawerHeader>
            <Typography>DESIGN YOUR MIDNIGHT</Typography>
            <IconButton onClick={() => setIsOpenDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </S.DrawerHeader>

          <S.CustomTabs
            value={tabIndex}
            onChange={handleChangeTab}
            variant="fullWidth"
            indicatorColor="secondary"
            aria-label="build your boat"
          >
            <Tab label="EXTERIOR" />
            <Tab label="INTERIOR" />
          </S.CustomTabs>

          <SwipeableViews
            axis="x"
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={tabIndex} index={0}>
              {selectedOption && selectedOption.type === "EXTERIOR" ? (
                <Slide direction="left" in={true}>
                  <div>
                    {selectedOptTitle}
                    <S.OptionsWrapper>
                      {selectedOption.options.map(option =>
                        handleOption(option)
                      )}
                    </S.OptionsWrapper>
                  </div>
                </Slide>
              ) : (
                exteriorItems.map((item, key) => (
                  <ConfiguratorItem
                    key={`exterior-${key}`}
                    item={item}
                    handleSelection={handleSelectConfigItem}
                  />
                ))
              )}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {selectedOption && selectedOption.type === "INTERIOR" ? (
                <Slide direction="left" in={true}>
                  <div>
                    {selectedOptTitle}
                    <S.OptionsWrapper>
                      {selectedOption.options.map((option, index) =>
                        handleOption(option, index)
                      )}
                    </S.OptionsWrapper>
                  </div>
                </Slide>
              ) : (
                interiorItems.map((item, key) => (
                  <ConfiguratorItem
                    key={`interior-${key}`}
                    item={item}
                    handleSelection={handleSelectConfigItem}
                  />
                ))
              )}
            </TabPanel>
          </SwipeableViews>
        </S.DrawerWrapper>
        <S.CopyrightWrapper>
          <Typography>
            Built by{" "}
            <a
              href="https://aftershock.agency/?utm_source=midnightboats+powered+by+link&utm_medium=link+on+bottom+of+website&utm_campaign=midnightboats+powered+by+link"
              target="_blank"
            >
              Aftershock
            </a>
          </Typography>
        </S.CopyrightWrapper>
      </Drawer>
    </Layout>
  )
}

export default BoatBuilder
