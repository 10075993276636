import React from "react"
import * as S from "../header/header.styles"
import { CustomAppBar, MenuBtn } from "../header/header.styles"
import TransitionLink from "../transition-link/transition-link.component"
import Logo from "../logo/logo.component"
import CustomButton from "../custom-button/custom-button.component"

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { navigate } from "gatsby-link"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const HeaderConfig = ({ handleOpenDrawer, boatName }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <CustomAppBar
      isTransparent={true}
      elevation={0}
      position="fixed">
      <div>
        <S.Wrapper maxWidth='xl'>
          <div>
            <S.Item>
              <MenuBtn
                onClick={() => navigate("/boat-builder")}
              >
                <ArrowBackIosIcon/>
                <span style={{ textTransform: "uppercase" }}>
                  MODEL {boatName}
              </span>
              </MenuBtn>
            </S.Item>
          </div>
          {matches &&
          <div>
            <TransitionLink url='/'>
              <Logo isDark={false}/>
            </TransitionLink>
          </div>
          }
          <div>
            <S.Item>
              <CustomButton
                onClick={handleOpenDrawer}
                color="secondary"
                className="lightBorder"
              >
                <span>EDIT <b> SCHEME</b></span>
              </CustomButton>
            </S.Item>
          </div>
        </S.Wrapper>
      </div>
    </CustomAppBar>
  )
}
export default HeaderConfig