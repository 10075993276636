import styled from "styled-components"
import { Typography } from "@material-ui/core"

export const Wrapper = styled.div`
  cursor: pointer;
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-bottom: 1px solid rgba(32, 32, 32, 0.08);
  width: 100%;
  background: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  p,
  span {
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  }

  span {
    color: rgba(64, 64, 64, 0.4);
  }

  &:focus {
    outline: none;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiSvgIcon-root {
    margin-left: 0.8em;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const ItemName = styled(Typography)`
  color: #2A334E;
  font-weight: 600;
  font-size:  ${({ theme }) => theme.typography.pxToRem(12)};
`