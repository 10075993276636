import styled from "styled-components"
import Link from "@material-ui/core/Link"

export const Wrapper = styled.div`
  padding: 2em 0;

  .circle-picker {
    width: 100% !important;
  }
`

export const TopWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 1em;
 p, span {
  color: #404040;
  opacity: .4;
  font-size:  ${({ theme }) => theme.typography.pxToRem(12)};
 }
`

export const CustomLink = styled(Link)`
  cursor: pointer;
  text-decoration: none !important;
  border-radius: 0;
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    color:  ${({ theme }) => theme.palette.primary.main};
    margin-left: .2em;
    position: relative;
    top: -1px;
  }
`
